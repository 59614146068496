<template>
	<v-container>
		<h1 class="text-h5 mt-4">Hoy <v-icon color="primary">mdi-clock</v-icon></h1>
		<div class="d-flex flex-wrap">
			<v-card
				width="100%"
				max-width="300"
				class="ma-2"
				outlined
				v-for="topCard in Object.values(todayCards)"
				:key="topCard.text"
				:loading="topCard.value == null"
			>
				<v-row class="pa-4">
					<v-col cols="auto" class="align-center d-flex">
						<v-avatar rounded="100" color="primary" size="50"
							><v-icon dark v-text="topCard.icon"
						/></v-avatar>
					</v-col>
					<v-col>
						<h2 v-text="topCard.value + topCard.unit" />
						<span v-text="topCard.texto" />
					</v-col>
				</v-row>
			</v-card>
		</div>

		<h1 class="text-h5 mt-4">
			Total <v-icon color="primary">mdi-calendar</v-icon>
		</h1>
		<div class="d-flex flex-wrap">
			<v-card
				width="100%"
				max-width="300"
				class="ma-2"
				outlined
				v-for="topCard in Object.values(totalCards)"
				:key="topCard.text"
				:loading="topCard.value == null"
			>
				<v-row class="pa-4">
					<v-col cols="auto" class="align-center d-flex">
						<v-avatar rounded="100" color="primary" size="50"
							><v-icon dark v-text="topCard.icon"
						/></v-avatar>
					</v-col>
					<v-col>
						<h2 v-text="topCard.value + topCard.unit" />
						<span v-text="topCard.texto" />
					</v-col>
				</v-row>
			</v-card>
		</div>
	</v-container>
</template>

<script>
export default {
	name: "Home",
	data() {
		return {
			todayCards: {
				abiertas: {
					texto: "Incidencias Abiertas",
					value: null,
					unit: "",
					icon: "mdi-alert-circle-outline",
				},
				cerradas: {
					texto: "Incidencias Cerradas",
					value: null,
					unit: "",
					icon: "mdi-check-all",
				},
				totales: {
					texto: "Incidencias Totales",
					value: null,
					unit: "",
					icon: "mdi-equal",
				},
				porcentaje: {
					texto: "Porcentaje Cerradas",
					value: null,
					unit: "%",
					icon: "mdi-percent-outline",
				},
			},
			totalCards: {
				abiertas: {
					texto: "Incidencias Abiertas",
					value: null,
					unit: "",
					icon: "mdi-alert-circle-outline",
				},
				cerradas: {
					texto: "Incidencias Cerradas",
					value: null,
					unit: "",
					icon: "mdi-check-all",
				},
				totales: {
					texto: "Incidencias Totales",
					value: null,
					unit: "",
					icon: "mdi-equal",
				},
				porcentaje: {
					texto: "Porcentaje Cerradas",
					value: null,
					unit: "%",
					icon: "mdi-percent-outline",
				},
			},
		};
	},
	mounted() {
		axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/incidencias.php`,
			params: {
				token: this.$store.getters.getToken,
			},
		}).then(({ data }) => {
			this.totalCards.totales.value = data.length;
			this.totalCards.cerradas.value = data.filter(
				(i) => i.estado == "Cerrada"
			).length;
			this.totalCards.abiertas.value = data.filter(
				(i) => i.estado == "Abierta"
			).length;

			this.totalCards.porcentaje.value =
				parseFloat((this.totalCards.cerradas.value * 100) / this.totalCards.totales.value || 0).toFixed(2);

			data = data.filter(
				(i) =>
					new Date().setHours(0, 0, 0, 0) ==
					new Date(Number(i.fecha)).setHours(0, 0, 0, 0)
			);

			this.todayCards.totales.value = data.length;
			this.todayCards.cerradas.value = data.filter(
				(i) => i.estado == "Cerrada"
			).length;
			this.todayCards.abiertas.value = data.filter(
				(i) => i.estado == "Abierta"
			).length;

			this.todayCards.porcentaje.value =
				parseFloat((this.todayCards.cerradas.value * 100) /
					this.todayCards.totales.value || 0).toFixed(2);
		});
	},
};
</script>
